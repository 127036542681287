<script>
import { mapState, mapGetters, mapActions } from "vuex";
import { QUALIFICATIONS_DESCRIPTIONS } from "@/package/const/qualifications-descriptions";
import MainLoader from "@/components/helpers/MainLoader.vue";
import QualificationPreview from "@/components/qualifications/QualificationPreview";
import QualificationProgress from "@/components/qualifications/QualificationProgress";
import QualificationItem from "@/components/qualifications/QualificationItem";
import AccountSelector from "@/components/AccountSelector";
import QualificationCyclesPreview from "@/components/qualifications/FinancialCyclesPreview";

export default {
  name: "Qualifications",

  components: {
    QualificationCyclesPreview,
    QualificationPreview,
    QualificationProgress,
    QualificationItem,
    AccountSelector,
    MainLoader,
  },

  data() {
    return {
      selectedQualification: {
        id: null,
      },

      loading: true,
      QUALIFICATIONS_DESCRIPTIONS: QUALIFICATIONS_DESCRIPTIONS,
      historyBinaryAccount: null,
    };
  },

  watch: {
    selectedBinaryAccount() {
      this.loading = true;

      this.loadQualification({
        binaryAccount: this.selectedBinaryAccount.id,
      }).finally(() => {
        this.setHistoryForBinaryAccount();
        this.loading = false;
      });
    },

    historyQualifications() {
      this.setHistoryForBinaryAccount();
    },

    // historyQualifications: {
    //   deep: true,
    //   immediate: true,
    //   handler() {
    //     this.setHistoryForBinaryAccount();
    //   },
    // },
  },

  computed: {
    ...mapState({
      user: (state) => state.auth.user,
      qualifications: (state) => state.qualification.qualifications,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
      qualification: (state) => state.qualification.qualification,
      invitedTSPCount: (state) => state.qualification.invitedTSPCount,
      historyQualifications: (state) =>
        state.qualification.historyQualifications,
    }),

    ...mapGetters({
      getUserBinaryAccountById: "auth/getUserBinaryAccountById",
    }),

    currentQualification() {
      return this.selectedBinaryAccount.qualification
        ? {
            code: this.selectedBinaryAccount.qualification.code,
            name: this.selectedBinaryAccount.qualification.name,
          }
        : { code: "without_qualification", name: "У вас нет квалификации" };
    },

    showQualificationPreviewTitle() {
      return !!this.selectedBinaryAccount.qualification;
    },

    binaryAccountQualification() {
      if (this.selectedBinaryAccount) {
        return this.selectedBinaryAccount.qualification;
      } else {
        return null;
      }
    },

    showFinancialCyclesPreview() {
      return (
        this.currentQualification.code === "without_qualification" ||
        this.currentQualification.code === "v_president"
      );
    },
  },

  methods: {
    ...mapActions({
      loadQualifications: "qualification/loadQualifications",
      loadQualification: "qualification/loadQualification",
      loadInvitedTSPCount: "qualification/loadInvitedTSPCount",
      loadHistoryQualifications: "qualification/loadHistoryQualifications",
    }),

    selectQualification(qualification) {
      if (qualification.id !== this.selectedQualification.id) {
        this.selectedQualification = qualification;
      } else {
        this.selectedQualification = {
          id: null,
        };
      }
    },

    getDescriptionByCode(code) {
      return this.QUALIFICATIONS_DESCRIPTIONS.find(
        (qualificationDescription) => qualificationDescription.code === code
      );
    },

    getDateQualificationByCode(code, id) {
      let foundQualification = null;

      foundQualification = this.historyBinaryAccount?.find(
        (item) => item.qualification === code
      );

      if (!foundQualification) {
        if (this.qualification?.qualification_id >= id) {
          return "without";
        }

        return null;
      }

      return foundQualification?.created_at;
    },

    setHistoryForBinaryAccount() {
      // if (
      //   !this.selectedBinaryAccount ||
      //   !this.historyQualifications ||
      //   !Object.keys(this.historyQualifications).length
      // )
      //   return;

      if (
        this.selectedBinaryAccount &&
        this.historyQualifications &&
        Object.keys(this.historyQualifications).length
      ) {
        this.historyBinaryAccount = this.historyQualifications[
          this.selectedBinaryAccount.id
        ];
      }
    },
  },

  mounted() {
    if (this.selectedBinaryAccount) {
      this.loadQualification({
        binaryAccount: this.selectedBinaryAccount.id,
      });
      this.loadInvitedTSPCount();
    }

    this.loadQualifications().finally(() => {
      this.loading = false;
    });

    this.loadHistoryQualifications();
  },
};
</script>

<template>
  <div class="qualifications">
    <div v-if="selectedBinaryAccount" class="account-selector-wrapper">
      <AccountSelector />
    </div>

    <div class="qualifications__content">
      <MainLoader v-if="loading" />

      <template v-else>
        <div v-if="qualification" class="qualifications__info">
          <QualificationPreview
            :title="showQualificationPreviewTitle"
            :qualification="currentQualification"
          />

          <div class="qualifications__progress">
            <QualificationProgress
              :qualification="qualification"
              :invited-tsp-count="invitedTSPCount"
            />
          </div>

          <QualificationCyclesPreview v-if="showFinancialCyclesPreview" />
        </div>

        <div class="qualifications__list">
          <QualificationItem
            v-for="qualification in qualifications"
            :key="qualification.id"
            :qualification="qualification"
            :user-qualification="binaryAccountQualification"
            :selected-qualification="selectedQualification"
            :qualification-description="
              getDescriptionByCode(qualification.code)
            "
            :date="
              getDateQualificationByCode(qualification.code, qualification.id)
            "
            @selectQualification="selectQualification"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="scss">
.qualifications {
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;

  &__content {
    padding: $space-xxl $space-xxl;
    background-color: $background-white;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;

    @media (min-width: 426px) and (max-width: 576px) {
      padding: $space-xxl $space-xl;
    }

    @media (max-width: 425px) {
      padding: $space-xxl $space-l;
    }

    @media (max-width: 375px) {
      padding: $space-xl $space-m;
    }
  }

  &__info {
    width: 336px;
    margin: 0 $space-xxl $space-xl 0;

    @media (max-width: 576px) {
      margin: 0 0 $space-xl 0;
    }
  }

  &__progress {
    margin: $space-xl 0 0;
  }

  .financial-cycles-preview {
    margin-top: $space-xxl;
  }

  &__list {
    width: 336px;
  }
}
</style>
