<script>
import { mapState, mapActions } from "vuex";
import ButtonRedirect from "@/components/helpers/ButtonRedirect";

export default {
  components: { ButtonRedirect },

  computed: {
    ...mapState({
      financialCycles: (state) => state.financialCycles.financialCycles,
      selectedBinaryAccount: (state) => state.auth.selectedBinaryAccount,
    }),
  },

  methods: {
    ...mapActions({
      loadFinancialCycles: "financialCycles/loadFinancialCycles",
    }),
  },

  mounted() {
    this.loadFinancialCycles({
      binaryAccountId: this.selectedBinaryAccount.id,
    });
  },
};
</script>

<template>
  <div v-if="financialCycles" class="financial-cycles-preview">
    <h4 class="financial-cycles-preview__title">Командные циклы</h4>
    <p class="financial-cycles-preview__subtitle">
      Один командный цикл - это сокращение 10 CV в правой и левой командах
    </p>

    <div class="financial-cycles-preview__cycles">
      <p>Циклов за текущую неделю</p>
      <span>{{ financialCycles.weeks.current_week.amount }}</span>
    </div>

    <button-redirect
      text="История циклов"
      arrow-position="right"
      button-style="background"
      @click="$router.push({ name: 'FinancialCycles' })"
    />
  </div>
</template>

<style lang="scss" scoped>
.financial-cycles-preview {
  &__title {
    @include body-1-bold;
    color: $dark-primary;
    margin-bottom: $space-xs;
  }

  &__subtitle {
    @include text-2;
    color: $dark-sixth;
    margin-bottom: $space-l;
  }

  &__cycles {
    @include text-2;
    color: $dark-primary;
    padding: 0 $space-l;
    display: flex;
    justify-content: space-between;
    margin-bottom: $space-m;
  }
}
</style>
