export const QUALIFICATIONS_DESCRIPTIONS = [
  {
    code: "start",
    name: "Старт",
    requirements: [
      {
        title: "Лично приглашенные L",
        value: "1",
      },
      {
        title: "Лично приглашенные R",
        value: "1",
      },
      {
        title: "PV",
        value: "50",
      },
    ],
    bonuses: [
      {
        title: "Линейный бонус",
        description: "5% от 1 поколения",
      },
    ],
  },
  {
    code: "expert",
    name: "Эксперт по продажам",
    requirements: [
      {
        title: "Циклов за первую неделю",
        value: "3",
      },
      {
        title: "Циклов за вторую неделю",
        value: "3",
      },
      {
        title: "Лично приглашенные L",
        value: "1",
      },
      {
        title: "Лично приглашенные R",
        value: "1",
      },
      {
        title: "PV",
        value: "800",
      },
      {
        title: "Подключенных ТСП",
        value: "1",
      },
      {
        title: "Квалификация от Старт",
        value: "1",
      },
    ],
    bonuses: [
      {
        title: "Линейный бонус",
        description: "Старт + 5% от 2 поколения",
      },
      {
        title: "Карьерный бонус",
        description: "20 000 RC",
      },
    ],
  },
  {
    code: "star",
    name: "Менеджер по продажам",
    requirements: [
      {
        title: "Циклов за первую неделю",
        value: "6",
      },
      {
        title: "Циклов за вторую неделю",
        value: "6",
      },
      {
        title: "Квалификация L",
        value: "от Старт",
      },
      {
        title: "Квалификация R",
        value: "от Старт",
      },
      {
        title: "Лично приглашенные L",
        value: "1",
      },
      {
        title: "Лично приглашенные R",
        value: "1",
      },
      {
        title: "PV",
        value: "1 600",
      },
      {
        title: "Подключенных ТСП",
        value: "1",
      },
    ],
    bonuses: [
      {
        title: "Линейный бонус",
        description: "Эксперт по продажам + 5% от 3 поколения",
      },
      {
        title: "Карьерный бонус",
        description: "40 000 RC",
      },
    ],
  },
  {
    code: "double_star",
    name: "Лидер продаж",
    requirements: [
      {
        title: "Циклов за первую неделю",
        value: "20",
      },
      {
        title: "Циклов за вторую неделю",
        value: "20",
      },
      {
        title: "Квалификация L",
        value: "от Эксперт по продажам",
      },
      {
        title: "Квалификация R",
        value: "от Эксперт по продажам",
      },
      {
        title: "PV",
        value: "8 000",
      },
      {
        title: "Подключенных ТСП",
        value: "1",
      },
    ],
    bonuses: [
      {
        title: "Линейный бонус",
        description: "Менеджер по продажам + 5% от 4 поколения",
      },
      {
        title: "Лидерский бонус",
        description: "1% от товарооборота на полгода",
      },
      {
        title: "Карьерный бонус",
        description: "100 000 RC",
      },
    ],
  },
  {
    code: "diamond",
    name: "Тимлидер продаж",
    requirements: [
      {
        title: "Циклов за первую неделю",
        value: "40",
      },
      {
        title: "Циклов за вторую неделю",
        value: "40",
      },
      {
        title: "Квалификация L",
        value: "от Менеджер по продажам",
      },
      {
        title: "Квалификация R",
        value: "от Менеджер по продажам",
      },
      {
        title: "PV",
        value: "20 000",
      },
      {
        title: "Подключенных ТСП",
        value: "1",
      },
    ],
    bonuses: [
      {
        title: "Линейный бонус",
        description: "Лидер продаж + 5% от 5 поколения",
      },
      {
        title: "Лидерский бонус",
        description: "Лидер продаж + 1% от товарооборота на полгода",
      },
      {
        title: "Карьерный бонус",
        description: "200 000 RC",
      },
    ],
  },
  {
    code: "double_diamond",
    name: "Топ-лидер продаж",
    requirements: [
      {
        title: "Циклов за первую неделю",
        value: "80",
      },
      {
        title: "Циклов за вторую неделю",
        value: "80",
      },
      {
        title: "Квалификация L",
        value: "от Лидер продаж",
      },
      {
        title: "Квалификация R",
        value: "от Лидер продаж",
      },
      {
        title: "PV",
        value: "40 000",
      },
      {
        title: "Подключенных ТСП",
        value: "1",
      },
    ],
    bonuses: [
      {
        title: "Линейный бонус",
        description: "Тимлидер продаж + 4% от 6 поколения",
      },
      {
        title: "Лидерский бонус",
        description: "Тимлидер продаж + 1% от товарооборота на полгода",
      },
      {
        title: "Карьерный бонус",
        description: "500 000 RC",
      },
    ],
  },
  {
    code: "grand_diamond",
    name: "Директор по продажам",
    requirements: [
      {
        title: "Циклов за первую неделю",
        value: "120",
      },
      {
        title: "Циклов за вторую неделю",
        value: "120",
      },
      {
        title: "Квалификация L",
        value: "от Тимлидер продаж",
      },
      {
        title: "Квалификация R",
        value: "от Тимлидер продаж",
      },
      {
        title: "PV",
        value: "100 000",
      },
      {
        title: "Подключенных ТСП",
        value: "1",
      },
    ],
    bonuses: [
      {
        title: "Линейный бонус",
        description: "Топ-лидер продаж + 3% от 7 поколения",
      },
      {
        title: "Лидерский бонус",
        description: "1% от товарооборота на полгода",
      },
      {
        title: "Карьерный бонус",
        description: "750 000 RC",
      },
    ],
  },
  {
    code: "diamond_elite",
    name: "Директор по продажам международного уровня",
    requirements: [
      {
        title: "Циклов за первую неделю",
        value: "200",
      },
      {
        title: "Циклов за вторую неделю",
        value: "200",
      },
      {
        title: "Квалификация L",
        value: "от Топ-лидер продаж",
      },
      {
        title: "Квалификация R",
        value: "от Топ-лидер продаж",
      },
      {
        title: "PV",
        value: "160 000",
      },
      {
        title: "Подключенных ТСП",
        value: "1",
      },
    ],
    bonuses: [
      {
        title: "Линейный бонус",
        description: "Топ-лидер продаж + 2% от 8 поколения",
      },
      {
        title: "Лидерский бонус",
        description: "1% от товарооборота на полгода",
      },
      {
        title: "Карьерный бонус",
        description: "1 000 000 RC",
      },
    ],
  },

  {
    code: "ambassador",
    name: "Амбассадор",
    requirements: [
      {
        title: "Циклов за первую неделю",
        value: "350",
      },
      {
        title: "Циклов за вторую неделю",
        value: "350",
      },
      {
        title: "Квалификация L",
        value: "от Директор по продажам",
      },
      {
        title: "Квалификация R",
        value: "от Директор по продажам",
      },
      {
        title: "PV",
        value: "300 000",
      },
      {
        title: "Подключенных ТСП",
        value: "1",
      },
    ],
    bonuses: [
      {
        title: "Линейный бонус",
        description: "Амбассадор + 1% от 9 поколения",
      },
      {
        title: "Лидерский бонус",
        description: "1% от товарооборота на полгода",
      },
      {
        title: "Карьерный бонус",
        description: "2 000 000 RC",
      },
    ],
  },
  {
    code: "v_president",
    name: "Вице-президент дистрибьюторской сети",
    requirements: [
      {
        title: "Циклов за первую неделю",
        value: "600",
      },
      {
        title: "Циклов за вторую неделю",
        value: "600",
      },
      {
        title: "Квалификация L",
        value: "от Директор по продажам международного уровня",
      },
      {
        title: "Квалификация R",
        value: "от Директор по продажам международного уровня",
      },
      {
        title: "PV",
        value: "500 000",
      },
      {
        title: "Подключенных ТСП",
        value: "1",
      },
    ],
    bonuses: [
      {
        title: "Линейный бонус",
        description: "Амбассадор + 1% от 10 поколения",
      },
      {
        title: "Лидерский бонус",
        description: "1% от товарооборота на полгода",
      },
      {
        title: "Лидерский бонус",
        description:
          "1% от товарооборота между всеми Вице-президент дистрибьюторской сети навсегда",
      },
      {
        title: "Карьерный бонус",
        description: "5 000 000 RC",
      },
    ],
  },
];
