var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"qualification-item",class:[
    {
      'qualification-item_active':
        this.selectedQualification.id === this.qualification.id,
    },
    { 'qualification-item_dark': _vm.isReceivedQualification },
  ]},[_c('div',{staticClass:"qualification-item__title",on:{"click":function($event){return _vm.$emit('selectQualification', _vm.qualification)}}},[_c('img',{staticClass:"qualification-item__img-color",attrs:{"src":_vm.imageColorPath,"alt":""}}),_c('img',{staticClass:"qualification-item__img-colorless",attrs:{"src":_vm.imageColorlessPath,"alt":""}}),_c('p',[_vm._v(" "+_vm._s(_vm.formatQualification(_vm.qualification.code))+" ")]),(_vm.formatDate)?_c('div',{staticClass:"qualification-item__date"},[_vm._v(" "+_vm._s(_vm.formatDate)+" ")]):_vm._e()]),(_vm.selectedQualification.id === _vm.qualification.id)?_c('div',{staticClass:"qualification-item__info info",class:[
      _vm.qualification.code + '__info',
      {
        info_dark: _vm.isReceivedQualification,
      },
    ]},[_c('ul',{staticClass:"info__requirements"},_vm._l((_vm.qualificationDescription.requirements),function(requirement){return _c('li',{key:requirement.title},[_c('div',[_c('p',{domProps:{"innerHTML":_vm._s(requirement.title)}}),_c('span',[_vm._v(_vm._s(requirement.value))])])])}),0),_c('h6',[_vm._v("Бонусы")]),_vm._l((_vm.qualificationDescription.bonuses),function(bonus){return _c('div',{key:bonus.title,staticClass:"info__bonus"},[_c('span',[_vm._v(_vm._s(bonus.title))]),_c('p',[_vm._v(_vm._s(bonus.description))])])})],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }